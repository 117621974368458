import React, { createContext, useState, useEffect } from "react";

const AppContext = createContext([{}, () => {}]);

const AppContextProvider = (props) => {
  const [test, setTest] = useState("Hej");
  const [windowDimensions, setWindowDimensions] = useState(null);
  const [loadedWindowDimensions, setLoadedWindowDimensions] = useState(null);
  const [stickyMenu, setStickyMenu] = useState("relative");
  useEffect(() => {
    window.addEventListener("scroll", handleStickyMenu);
    return () => {
      window.removeEventListener("scroll", handleStickyMenu);
    };
  }, []);
  const handleStickyMenu = () => {
    if (window !== undefined) {
      let scrollYPos = window.scrollY;
      scrollYPos > 70 ? setStickyMenu("fixed") : setStickyMenu("relative");
    }
  };
  return (
    <AppContext.Provider
      value={{
        test,
        windowDimensions,
        setWindowDimensions,
        loadedWindowDimensions,
        setLoadedWindowDimensions,
        stickyMenu,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};
export { AppContext, AppContextProvider };
