import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      se: {
        translations: {
          mainTitle: 'Du förtjänar att känna dig trygg',
          download: 'Ladda ner appen',
          puffTitle: 'SafePal vid din sida',
          puffDescFirst:
            'Över 2.7 miljoner svenskar upplever otrygghet till den grad att de undviker vardagliga aktiviteter. Nu är det dags att vi skapar det trygga samhälle vi förtjänar!',
          puffDescSecond:
            'Med vår app bjuder du in dina SafePals som finns med dig hela tiden. Det kan t.ex. vara vännen, syskonet eller föräldern du vill ska kunna finnas där som stöd när du känner dig otrygg. Appen funkar som en trygghetsåtgärd för alla, oavsett vem du är.',
          puffDescThird:
            'Vår vision är att ALLA förtjänar att känna sig trygga, oavsett ekonomisk situation, geografisk plats, läggning, ålder, etc.',
          miniPuffFirstTitle: 'Så funkar det',
          miniPuffFirstDesc:
            'Att vara SafePal till någon kostar ingenting och för att ha SafePals betalar du en månatlig prenumeration på 49 kr. Prenumerationen är utan varken bindningstid eller uppsägningstid och du kan avsluta den när som helst.',
          miniPuffFirstBtn: 'Kolla in',
          miniPuffSecondTitle: 'Djupgående tjänster',
          miniPuffSecondDesc:
            'Är du offentlig person eller bara känner att du vill ha mer information om personen du ska släppa in i ditt liv? Då är våra djupgående tjänster något för dig.',
          miniPuffSecondBtn: 'Läs mer',
          miniPuffThirdTitle: 'Kontakt',
          miniPuffThirdDesc: 'Vid frågor och intresse kan du kontakta oss på',
          sliderFirstTitle: 'Säkerhet i allt vi gör',
          sliderFirstDesc:
            'När du skapar ett konto i appen gör du det med hjälp av ditt Mobila BankID. Vi har valt att använda oss av Mobilt BankID för att du ska känna dig trygg med att ditt konto är säkert.',
          sliderSecondTitle: 'Funktioner för din trygghet',
          sliderSecondDesc:
            'Efter att du loggat in möts du av din hemskärm. På den hittar du de funktioner du kan använda dig av för att känna trygghet i annars kanske otrygga situationer. Som att gå hem själv från krogen t.ex.',
          sliderThirdTitle: 'SafePals - när du behöver dem',
          sliderThirdDesc:
            'Kärnan i appen är dina SafePals. Det kan vara syskonet, föräldern eller en vän. De personer som du vill ska hålla dig i handen digitalt.',
          sliderForthTitle: 'Överfallsalarm',
          sliderForthDesc:
            'Om något skulle hända som gör att du vill skicka ut en alarmerande notis till dina SafePals använder du överfallslarmet. De kommer även att kunna följa vart du är om du har platstjänster aktiverat i telefonen.',
          sliderFifthTitle: 'Snabbkollen',
          sliderFifthDesc:
            'Med Snabbkollen kan du direkt få bekräftat att personen du är i kontakt med är den som hen utger sig för att vara. När du skriver in hens telefonnummer får personen ett sms där hen verifierar sig med bankid.',
          howPuffFirstTitle: 'Att vara en SafePal är helt kostnadsfritt',
          howPuffFirstDesc:
            'Vi vill att du som använder vår app ska ha möjlighet att ha alla SafePals du känner att du behöver. Därför kostar det ingenting för dina SafePals att vara med.',
          howPuffFirstPrice: '',
          howPuffSecondTitle: 'SafePal+',
          howPuffSecondDesc:
            'Med SafePal+ kan du använda alla funktioner i appen helt obegränsat. Du får även 10 st Snabbkoller per månad.',
          howPuffSecondPrice: 'kr/månad',
          footerTitleFirst: 'BESÖKSADRESS',
          footerTitleSecond: 'SAMARBETSPARTNERS',
          footerTitleThird: 'LÄNKAR',
          footerLinksFirst: 'Köpvillkor',
          footerLinksSecond: 'Integritetspolicy',
          miscDownload: 'Ladda ner',
          miscChat: 'Chatta med oss',
          intPolicyTitle: 'Integritetspolicy',
          intPolicyDesc:
            "Denna integritetspolicy beskriver vilken typ av personlig information SafePal (hädanefter 'vi' eller 'oss') samlar in från våra användare av appen, hur vi använder denna information och vilka åtgärder vi vidtar för att skydda din personliga information. Genom att använda våra tjänster godkänner du villkoren i denna integritetspolicy.",
          intPolicyInfoSubTitle: 'Informationen vi samlar in',
          intPolicyInfoParaFirst:
            'Vi samlar in informationen du anger när du skapar ett konto i appen:',
          intPolicyInfoParaFirt:
            'Vi samlar in personlig information som du tillhandahåller när du skapar ett konto, såsom:',
          intPolicyInfoBulletName: 'Namn',
          intPolicyInfoBulletEmail: 'E-postadress',
          intPolicyInfoBulletPhone: 'Telefonnummer',
          intPolicyInfoBulletSsn: 'Personnummer',
          intPolicyInfoBulletUser: 'Användar-ID',
          intPolicyInfoBulletDevice: 'Enhets-ID',
          intPolicyInfoParaSecond:
            'Vi samlar även in annan information om dig eller din mobila enhet om du använder någon funktion i appen',
          intPolicyInfoBulletPayment:
            'Betalningsinformation (In App Purchase, köp av funktion)',
          intPolicyInfoBulletPayment:
            'Platsdata (Walk me home, Incheckning, Date Mode)',
          intPolicyInfoBulletPayment:
            'Kontakter/telefonbok (Inbjudan av SafePals)',
          intPolicyInfoBulletPayment: 'Bilder från biblioteket (Profilbild)',
          intPolicyInfoBulletPayment:
            'Spela in ljud (Valfritt om du använder överfallslarmet)',
          intPolicyInfoBulletLocation:
            'Platstjänster (Walk me home, Check in, Date Mode)',
          intPolicyInfoBulletContacts:
            'Kontakter/Telefonbok (Vid inbjudan av en SafePal)',
          intPolicyInfoBulletPictures: 'Bilder från biblioteket (Profilbild)',
          intPolicyInfoBulletSound:
            'Ljudinspelning (Valfritt vid användning av överfallslarmet)',
          intPolicyInfoParaThird:
            'Information om dig eller din mobila enhet samlas endast in när du skapar ett konto eller använder någon av ovan nämnda funktioner i appen.',
          intPolicyUsageTitle: 'Användning av informationen',
          intPolicyUsageParaFirst:
            'Vi använder den personliga informationen vi samlar in för att tillhandahålla och förbättra funktionerna i appen, hantera ditt konto, behandla betalningar och hantera supporten. Vi kan också använda den personliga informationen för att uppfylla lagliga skyldigheter, såsom att tillhandahålla information till myndigheter.',
          intPolicyShareTitle: 'Delning av Informationen',
          intPolicyShareParaFirst:
            'Vi delar inte din personliga information med tredje part för marknadsföringsändamål utan ditt samtycke. Vi kan emellertid dela din personliga information med våra betrodda partners som tillhandahåller tjänster för oss, såsom betalningsbehandlare och IT-tjänsteleverantörer (t.ex. AWS). Vi säkerställer att vår hantering av din data är i enlighet med GDPR.',
          intPolicySafetyTitle: 'Säkerhet',
          intPolicySafetyParaFirst:
            'Vi vidtar lämpliga tekniska och organisatoriska åtgärder för att skydda din personliga information mot obehörig åtkomst, ändring, förstörelse eller förlust. Vi begränsar åtkomsten till din personliga information endast till de anställda och tjänsteleverantörer som behöver tillgång till den för att utföra sina arbetsuppgifter.',
          intPolicyRightsTitle: 'Dina Rättigheter',
          intPolicyRightsParaFirst:
            'Du har rätt att begära tillgång till och rättelse av din personliga information som vi har om dig. Du har också rätt att begära radering av din personliga information, under förutsättning att det inte krävs för att uppfylla våra lagliga skyldigheter. Om du har några frågor om vår integritetspolicy eller hur vi behandlar din personliga information, kan du kontakta oss genom att använda kontaktuppgifterna nedan.',
          intPolicyContactTitle: 'Kontaktinformation',
          intPolicyContactParaFirst: 'E-post:',
          paymentTermsTitle: 'Köp- och leveransvillkor',
          paymentTermsDesc:
            'Tjänsten/tjänsterna tillhandahålls av SafeDate AB, 559361-6146, Strandvägen 47, 114 56, Stockholm , info@safedate.se.',
          paymentTermsSubTitleService: 'Tjänsten',
          paymentTermsServiceParaFirst:
            'Tjänsten består av en webb-/appbaserad plattform där användaren kan köpa tjänster som levereras direkt efter beställning eller inom en angiven tidsram.',
          paymentTermsServiceParaSecond:
            'För att kunna nyttja tjänsten krävs att användaren registrerar ett användarkonto. BankID krävs för registrering av konto och inloggning i tjänsten. Villkor för användarkontot anges i aktuella användarvillkor.',
          // paymentTermsAdditionalSubTitle: "Tilläggstjänster",
          // paymentTermsAdditionalParaFirst: "Tjänsten innehåller ett antal tilläggstjänster som delvis kan användas kostnadsfritt. För att nyttja tilläggstjänsterna obegränsat behöver användaren antingen göra ett engångsköp eller starta en prenumeration.",
          paymentTermsPriceSubTitle: 'Priser och betalning',
          paymentTermsPriceParaFirst:
            'För varje prenumerationstjänst och tilläggstjänst anges ett pris inklusive moms. Betalningsalternativ framgår i slutet av köpprocessen. Betalning erläggs före leverans.',
          paymentTermsPriceParaSecond:
            'Prenumerationstjänster debiteras månadsvis i förskott, inför övergången till en ny månad via användarens registrerade betalkort.',
          paymentTermsPriceParaThird:
            'Prenumerationstjänster löper månadsvis och kan avslutas senast 10 dagar före månadsskifte, under förutsättning att bindningstid inte löper.',
          paymentTermsDeliverySubTitle: 'Leverans',
          paymentTermsDeliveryParaFirst:
            'Efter köp av tilläggstjänst levereras tjänsten i många fall direkt efter beställning och betalning. I vissa fall är leveranstiden längre, t ex där manuell handpåläggning krävs.',
          paymentTermsRegretSubTitle: 'Ångerrätt',
          paymentTermsRegretParaFirst:
            'Om tjänst eller tilläggstjänst levereras direkt efter köp gäller inte ångerrätt. Om användaren ändå skulle ångra sitt köp hänvisas till reklamationsreglerna nedan.',
          paymentTermsRegretParaSecond:
            'Om tjänst eller tilläggstjänst inte levereras direkt har användare rätt att ångra sitt köp fram tills att tjänsten levererats.',
          paymentTermsRegretParaThird:
            'Om användare vill nyttja ångerrätt enligt ovan ska denne kontakta SafePal.',
          paymentTermsRegretParaForth:
            'Om användare nyttjat ångerrätt har denne rätt att erhålla återbetalning för erlagd betalning senast 30 dagar efter att vi har mottagit och godkänt ditt meddelande om ånger.',
          paymentTermsSendBackSubTitle: 'Reklamation',
          paymentTermsSendBackParaFirst:
            'Om du inom 30 dagars tid efter genomfört köp skulle vara missnöjd med tjänstens innehåll eller kvalitet i förhållande till vår marknadsföring eller dina förväntningar, var god kontakta oss via mail.',
          paymentTermsInfoSubTitle: 'Personuppgifter',
          paymentTermsInfoParaFirst:
            'I samband med registrering av konto och köp av tjänst/tilläggstjänst samlar vi in personuppgifter i syfte att kunna fullgöra vårt avtal med användaren samt att fullgöra våra skyldigheter enligt lag. För mer information om vår behandling av personuppgifter samt dina rättigheter hänvisar vi dig till vår integritetspolicy.',
          lexbase:
            'Nu introducerar vi samarbetet Lexbase x SafePal. Enligt Brottsförebyggande rådet (BRÅ) uppgav var fjärde svensk 2023 att de känner sig så pass otrygga i samhället att de undviker vardagliga aktiviteter. Det är en oroande statistik som vi på SafePal ser som en uppmaning att agera. I vår nya uppdaterade snabbkoll granskas personen mot Lexbase databas och du får respons direkt i appen om hen finns med i deras register eller ej.',
        },
      },
      en: {
        translations: {
          mainTitle: 'You deserve to feel safe',
          download: 'Download the app',
          puffTitle: 'SafePal by your side',
          puffDescFirst:
            "Over 2.7 milion swedes feels that they are not safe so the extent that they avoid doing normal daily activities. It's about time we create the safe society we deserve!",
          puffDescSecond:
            'With our app you invite your SafePals that will be with you all the time. It can be your friend, sibling or parent that you want to have close when you feel unsafe. The app works as a safety measure for all, regardless of who you are.',
          puffDescThird:
            'Our vision is for everyone to feel safe no matter economical conditions, location, age, etc.',
          miniPuffFirstTitle: 'How it works',
          miniPuffFirstDesc:
            'To be a SafePal for someone is free of charge and in order to have SafePals you pay a montly subscription based fee of 49kr. You can cancel the subscription at any time.',
          miniPuffFirstBtn: 'Check it out',
          miniPuffSecondTitle: 'Deep diving services',
          miniPuffSecondDesc:
            "Are you a public person or maybe you just feel that you want to know more about a person you're letting in to your life? Then, our deep diving services are something for you.",
          miniPuffSecondBtn: 'Read more',
          miniPuffThirdTitle: 'Contact',
          miniPuffThirdDesc: 'If you have questions you can contact us at',
          sliderFirstTitle: 'Safety in everything we do',
          sliderFirstDesc:
            "When you create you account in the app, you use your Mobile Bank ID. We've chosen to use Mobile Bank ID for your safety.",
          sliderSecondTitle: 'Functions for your safety',
          sliderSecondDesc:
            'After signing in, you will land on your home screen. There, you will find all the functions you can use to feel safe in situations that might feel unsafe. Like walking home alone from the night club.',
          sliderThirdTitle: 'SafePals - when you need them',
          sliderThirdDesc:
            'The core of the app is your SafePals. The persons you want to be able to digitally hold your hand. It can be your sibling, your parent or a friend.',
          sliderForthTitle: 'Emergency alarm',
          sliderForthDesc:
            "If something were to happen to you and you want to notify your SafePals about it, you use the built-in emergency alarm. When the notification goes out to your SafePals they will also be able to see your location if you've allowed location services in your phone.",
          sliderFifthTitle: 'Fast check',
          sliderFifthDesc:
            "With Fast Check you're able to instantly get confirmation that the person you're in contact with in fact is who he/she says he/she is. When you provide the mobile number of the person you want to check, the person gets a text message where he/she is able to authenticate with Mobile Bank ID. In the process, the person are checked in Lexbase's database and you get instant verifcation in the app about who the person is and if he/she exists in Lexbase's database. The results are then visualized and saved in the app.",
          howPuffFirstTitle: 'You can be a SafePal for free',
          howPuffFirstDesc:
            'We want you to be able to have all the SafePals you feel that you need. Therefore, being a SafePal is free of charge.',
          howPuffFirstPrice: '',
          howPuffSecondTitle: 'SafePal+',
          howPuffSecondDesc:
            'With SafePal+ you can use all the functions in the app as many times as you want. You also get 10 Fast checks included per month.',
          howPuffSecondPrice: 'SEK/month',
          footerTitleFirst: 'COMPANY INFORMATION',
          footerTitleSecond: 'CONTACT US',
          footerTitleThird: 'LINKS',
          footerLinksFirst: 'Payment terms',
          footerLinksSecond: 'Privacy policy',
          miscDownload: 'Download',
          miscChat: 'Chat with us',
          intPolicyTitle: 'Privacy policy',
          intPolicyDesc:
            "This privacy policy describes what type of information SafePal (henceforth 'we' or 'us') collect from our users in the app, how we use the information and what actions we take in order to protect your personal information. By using the app you agree to this privacy policy.",
          intPolicyInfoSubTitle: 'Information we collect',
          intPolicyInfoParaFirst:
            'We collect the information you provide when you create an account in the app:',
          intPolicyInfoBulletName: 'Name',
          intPolicyInfoBulletEmail: 'Email',
          intPolicyInfoBulletPhone: 'Phone number',
          intPolicyInfoBulletSsn: 'Personal number (via Mobile Bank ID)',
          intPolicyInfoBulletUser: 'User ID',
          intPolicyInfoBulletDevice: 'Device ID',
          intPolicyInfoParaSecond:
            'Wa also collect information about you or your device when you use a function in the app:',
          intPolicyInfoBulletPayment:
            'Payment information (In App Purchase, function purchase)',
          intPolicyInfoBulletLocation:
            'Location services (Walk me home, Check in, Date Mode)',
          intPolicyInfoBulletContacts:
            'Contacts/Phone book (When inviting a SafePal)',
          intPolicyInfoBulletPictures: 'Pictures from library (Profile image)',
          intPolicyInfoBulletSound:
            'Sound recording (Optional when using the emergancy alarm)',
          intPolicyInfoParaThird:
            "Information about you or your mobile device is collected only when you've created an account or use a funtion in the app.",
          intPolicyUsageTitle: 'Information usage',
          intPolicyUsageParaFirst:
            'We use your personal information in order for the functions to work, handle your user account, handle payments and provide support to you. We might also use your personal information in order to obide the law, eg. when providing information to the authorities.',
          intPolicyShareTitle: 'Information sharing',
          intPolicyShareParaFirst:
            'We do not share you personal information with third parties for advertisement purposes without your consent. We can share your personal information to trusted partners that is providing services to us like payment processors and cloud services (eg. AWS). We make sure that all our data handling is done according to GDPR.',
          intPolicySafetyTitle: 'Safety',
          intPolicySafetyParaFirst:
            'We take appropriate technical and organisational measures in order to protect your personal information against unauthorized access, change, destruction or loss. We limit the access to your personal information to employees and trusted partners that needs the information in order to perform their duties to us.',
          intPolicyRightsTitle: 'Your rights',
          intPolicyRightsParaFirst:
            "You have the right to request access to, and correction of your personal information that we've collected about you. You also have the right to request deletion of your personal information, granted that we need the information in order to obide with the law. If you have any questions regarding this privacy policy or about how we process your personal information, please contact us by using the contact information below.",
          intPolicyContactTitle: 'Contact information',
          intPolicyContactParaFirst: 'Email:',
          paymentTermsTitle: 'Purchase and delivery terms',
          paymentTermsDesc:
            'SafePal is provided by SafePal Company AB, 559361-6146, Strandvägen 47, 114 56, Stockholm , info@safedate.se.',
          paymentTermsServiceSubTitle: 'The service',
          paymentTermsServiceParaFirst:
            'The service consists of a web and app based platform where the user can subscribe to functions that are accessible instantly after the purchase is complete or within a given time frame.',
          paymentTermsServiceParaSecond:
            'In order to use the service and its functions the user need to creat a user account. Mobile Bank ID is required for account creation and to be able to log in. User terms are stated in current user terms.',
          // paymentTermsAdditionalSubTitle: "Tilläggstjänster",
          // paymentTermsAdditionalParaFirst: "Tjänsten innehåller ett antal tilläggstjänster som delvis kan användas kostnadsfritt. För att nyttja tilläggstjänsterna obegränsat behöver användaren antingen göra ett engångsköp eller starta en prenumeration.",
          paymentTermsPriceSubTitle: 'Prices and payment',
          paymentTermsPriceParaFirst:
            'The price for subscriptions and additional services is stated with VAT included. Payment options are stated at the end of the payment process. Payment are done before delivery.',
          paymentTermsPriceParaSecond:
            'Subscriptions are billed on a monthly basis and in advance at the turn of the month via the registered credit card.',
          paymentTermsPriceParaThird:
            'Subscriptions run on a monthly basis and can be cancelled 10 days before the turn of the month.',
          paymentTermsDeliverySubTitle: 'Delivery',
          paymentTermsDeliveryParaFirst:
            'After purchasing a subscription or an additional service, the service is delivered i most cases instantly after order and payment. In some cases, the delivery time is longer, eg. when manual action is needed. Deliver',
          paymentTermsRegretSubTitle: 'Right of withdrawal',
          paymentTermsRegretParaFirst:
            'If the subscription or additional service is delivered instantly, right of withdrawal does not apply. If the user still wishes to invoke the right of withdrawal, we refer to the rules for withdrawal below.',
          paymentTermsRegretParaSecond:
            'If the subscription or additional service is not delivered instantly, the user have the right of withdrawal until the service is delivered.',
          paymentTermsRegretParaThird:
            'If the user wants to invoke the right of withdrawal acoording to above, SafePal shuld be contacted via email.',
          paymentTermsRegretParaForth:
            'If the user has invoked the right of withdrawal, the user have the right to obtain a refund for payment made. The refund is payed within 30 days after we received and approved the withdrawal.',
          paymentTermsSendBackSubTitle: 'Reclaim',
          paymentTermsSendBackParaFirst:
            "If the user is not satisfied with contents of the service compared to what we've advertised within 30 days from payment, the user should contact us via email.",
          paymentTermsInfoSubTitle: 'Personal information',
          paymentTermsInfoParaFirst:
            'When the user creates and account or buys a subscription or additional service we collect personal information in order to fulfill our agreement with the user and to comply with the law. For more information about how we handle personal information and your rights, please refer to out privacy policy.',
          lexbase:
            "Now we introduce the colab Lexbase x SafePal. According to BRÅ, every fourth Swede said that they feel so unsafe that they avoid daily acitivites. That's alarming statistics that SafePal wants to act on. In our new Fast Check the person is reviewed against the Lexbase database and you get instant information about if the person exists in their register or not.",
        },
      },
    },
    fallbackLng: 'se',
    debug: true,

    // have a common namespace used around the full app
    ns: ['translations'],
    defaultNS: 'translations',

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
