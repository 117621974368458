import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/pro-light-svg-icons";
import { AppContext } from "../appContext";
import "./service-block.css";

function ServiceBlock(props) {
  const context = useContext(AppContext);
  const ImageComp = (props) => {
    return (
      <div
        style={{
          position: "relative",
        }}
      >
        <img
          src={props.data.imageUrl}
          style={{
            width: "100%",
            maxWidth: 300,
            // height: 600,
            borderRadius: 150,
            ...props.imageStyles,
          }}
        />
        <div
          style={{
            position: "absolute",
            background: "#e8d3e5",
            right: 20,
            bottom: 20,
            borderRadius: 50,
            height: 100,
            width: 100,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: 22,
              fontWeight: "600",
              display: "flex",
              justifyContent: "center",
              alignItems: "baseline",
            }}
            className="no-margin"
          >
            {props.data.price}{" "}
            <span style={{ fontSize: 16, marginLeft: 3 }}>kr</span>
          </p>
        </div>
      </div>
    );
  };
  const TextComp = (props) => {
    return (
      <>
        <h2>{props.data.title}</h2>
        {props.data.desc.map((d, i) => {
          return <p>{d}</p>;
        })}
        {props.data.bullets.map((bull, i) => {
          return (
            <p style={{ display: "flex", alignItems: "center" }}>
              <img
                src={require("../images/shield.png")}
                style={{ width: 14, marginRight: 10 }}
              />
              {bull}
            </p>
          );
        })}
        <p className="bold" style={{ color: "#78408f" }}>
          {props.data.delivery}
        </p>
        <a href={props.data.download ? "/" : "/order"} className="cta">
          {props.data.download ? "Ladda ner appen" : "Beställ"}
        </a>
      </>
    );
  };

  return (
    <div className="service-block">
      <div className="cols">
        {context.loadedWindowDimensions &&
        context.loadedWindowDimensions.width < 920 ? (
          <>
            <div className={`two-col ${!props.index % 2 === 0 ? "image" : ""}`}>
              <ImageComp {...props} />
            </div>
            <div className={`two-col ${props.index % 2 === 0 ? "image" : ""}`}>
              <TextComp {...props} />
            </div>
          </>
        ) : (
          <>
            <div className={`two-col ${!props.index % 2 === 0 ? "image" : ""}`}>
              {props.index % 2 === 0 ? (
                <TextComp {...props} />
              ) : (
                <ImageComp {...props} />
              )}
            </div>
            <div className={`two-col ${props.index % 2 === 0 ? "image" : ""}`}>
              {props.index % 2 === 0 ? (
                <ImageComp {...props} />
              ) : (
                <TextComp {...props} />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default ServiceBlock;
