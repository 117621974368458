import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import apple from '../images/apple.png';
import google from '../images/google.png';
import './ui-layout.css';
import MainMenu from '../components/main-menu';
import Footer from '../components/footer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faGlobe } from '@fortawesome/pro-light-svg-icons';
import { AppContext } from '../appContext';
import { useTranslation, Trans } from 'react-i18next';

function UILayout(props) {
  const context = useContext(AppContext);
  const [showModal, setShowModal] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const { download } = useParams();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const downloadParam = queryParams.get('download');

    // Check if download=1 is present
    if (downloadParam === '1') {
      setShowModal(true);
    }
    props.setCurrentRoute(
      location.pathname === '/' ? 'home' : location.pathname.split('/')[1]
    );
  }, []);

  return (
    <div className="ui-wrapper">
      <MainMenu
        currentRoute={props.currentRoute}
        setShowModal={setShowModal}
        showModal={showModal}
        setShowMobileMenu={setShowMobileMenu}
        showMobileMenu={showMobileMenu}
      />
      <div className={`content-wrapper ${context.stickyMenu}`}>
        {props.children}
      </div>
      <Footer setShowModal={setShowModal} showModal={showModal} />
      {showModal ? (
        <div
          className={`modal ${showModal ? 'show' : ''}`}
          onClick={() => {
            setShowModal(false);
          }}
        >
          <div
            className="inner"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <h2>{t('download')}</h2>
            <div className="app-btn-wrp">
              {/* <p style={{ color: "#fff" }}>👇 {t("download")} 👇</p> */}
              <div className="inner">
                <a
                  href="https://apps.apple.com/se/app/safepal-stay-safe/id6446502380"
                  target="_blank"
                >
                  <img
                    alt="Apple download"
                    src={apple}
                    className="app-btn apple"
                  />
                </a>
                <a
                  href="https://play.google.com/store/apps/details?id=com.sweden.safepal"
                  target="_blank"
                >
                  <img
                    alt="Google download"
                    src={google}
                    className="app-btn google"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {/* <div
        className={`mobile-menu ${showMobileMenu ? "active" : ""} ${
          context.stickyMenu
        }`}
      >
        <button
          className="mobile-menu-close"
          onClick={() => {
            setShowMobileMenu(false);
          }}
        >
          <FontAwesomeIcon
            icon={faTimes}
            style={{ fontSize: 40, color: "#fff" }}
          />
        </button>
        <h2>MOBIL MENY</h2>
        <div className="links">
          <div className="links">
            <a
              className={props.currentRoute === "how-it-works" ? "active" : ""}
              href="/how-it-works"
            >
              {t("miniPuffFirstTitle")}
            </a>
            <div className="lang-btns mobile">
              <FontAwesomeIcon
                icon={faGlobe}
                style={{ marginRight: 5, color: "#fff" }}
                color={"#fff"}
              />
              <button
                className={`${
                  i18n.language === "se" ? "active" : "not-active"
                }`}
                onClick={() => {
                  changeLanguage("se");
                }}
              >
                SE
              </button>
              <button
                className={`${
                  i18n.language === "en" ? "active" : "not-active"
                }`}

                onClick={() => {
                  changeLanguage("en");
                }}
              >
                EN
              </button>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default UILayout;
