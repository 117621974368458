import React from "react";
import ServiceBlock from "../components/service-block";
import "./services.css";

function Services(props) {
  const data = [
    {
      title: "Fördjupad trygghetskoll",
      desc: [
        "Fördjupad Trygghetskoll kan utföras på en eller båda parter. Denna tjänst är transparent, och faktan vi hämtar är dokumenterad hos svenska myndigheter.",
        "Informationskällor: Polismyndigheten, Åklagarmyndigheten, Tingsrätter, Hovrätter, Högsta Domstolen, Förvaltningsdomstolar, Kammarrätter, Högsta Förvaltningsdomstolen, Arbetsdomstolen, Hyresnämnden, Skatteverket, Bolagsverket, Bisnode, Transportstyrelsen",
      ],
      bullets: [
        "Inkomstuppgifter 2 år tillbaka",
        "Betalningsanmärkningar",
        "Kronofogdeärenden",
        "Adress hen är skriven på samt eventuella övriga skrivna på samma adress",
        "Domar de senaste 10 åren",
        "Åtal senaste 10 åren",
        "Bolagsengagemang",
        "Fastighetsinnehav",
      ],
      price: 2850,
      delivery: "Leverans inom 24 tim",
      imageUrl: require("../images/services/couple.png"),
    },
    {
      title: "Säkerhetssamtal",
      desc: [
        "Ett säkerhetssamtal ger en större trygghet om den möjliga blivande partnerns förmåga att ge dig trygghet, tillit och lojalitet utifrån sin livsstil och vanor.",
        "Samtalen genomförs digitalt",
        "Säkerhetssamtalet syftar till att ge en bild av den potentiellt blivande/nuvarande partnerns med nyckelorden:",
      ],
      bullets: [
        "Livssituation",
        "Tidigare förhållanden och separationer",
        "Besvikelser i livet",
        "Arbete och karriär",
        "Skulder och dyra vanor",
        "Alkohol och drogbruk",
        "Eventuella sjukdomar",
        "Umgänge och livsstil",
      ],
      price: 2850,
      delivery: "Bokas inom 24 tim",
      imageUrl: require("../images/services/hands.png"),
    },
    {
      title: "Koll sociala medier",
      desc: [
        "Att göra en kartläggning av en persons aktivitet och närvaro på sociala medier kan ge en tydligare bild av vem personen är, vad hen står för och hur hen uttrycker sig och interagerar med andra. Det ger också en bild av vilka andra hen är connectad med och i vilka sociala digitala kretsar hen har kontakt med andra.",
        "För att vi ska ha förutsättning att göra detta på grundligast möjliga sätt ber vi om så extensiv information som möjligt gällande profiler på sociala medier, alias och mailadresser.",
      ],
      bullets: [],
      price: 2850,
      delivery: "Leverans inom 24 tim",
      imageUrl: require("../images/services/social_media.png"),
    },
    {
      title: "Djupintervju",
      desc: [
        "När allt kommer omkring är det ju ”rätt människa” just för dig som du önskar inleda en relation med. Individuella djupintervjuer är ett beprövat sätt för att få fram en tydligare bild av vem man är, vilka värderingar man har, vad man drivs av, hur man tar sig an motgångar, hur man ser till att skapa framgång och vilken självbild man har. Utifrån ett frågeformulär som tagits fram med hjälp av professionella och erfarna experter inom säkerhet och intervjuteknik, skapar vi en trygg miljö där vi samtalar kring dessa frågeställningar.",
      ],
      bullets: [],
      price: 2850,
      delivery: "Bokas inom 24 tim",
      imageUrl: require("../images/services/interview.png"),
    },
    {
      title: "Referenstagning",
      desc: [
        "När man ska ”rekrytera” en ny potentiell partner in i sitt liv kan det vara klokt att ta referenser. Hen väljer själv vilka 3 referenter hen vill att vi ska kontakta varefter en skriftlig sammanställning av svaren görs. Det man framförallt vill se är att det finns en samstämmighet i svaren från de olika referenterna. De personer hen lämnar som referenter kan t ex vara hens bästa vän, ett syskon eller en kollega på jobbet.",
        "Referenstagning genomförs digitalt.",
      ],
      bullets: [
        // "Livssituation",
        // "Tidigare förhållanden och separationer",
        // "Besvikelser i livet",
        // "Arbete och karriär",
        // "Skulder och dyra vanor",
        // "Alkohol och drogbruk",
        // "Eventuella sjukdomar",
        // "Umgänge och livsstil",
      ],
      price: 2850,
      delivery: "Bokas inom 24 tim",
      imageUrl: require("../images/services/reference.png"),
    },
  ];
  return (
    <>
      <div className="services">
        <div className="section">
          <h1>Våra tjänster</h1>
          <h4>
            För oss är det viktigt att kunna erbjuda så många som möjligt en
            chans till trygghet, oavsett ekonomisk situation. Därför är vårt
            erbjudande uppdelat i olika tjänster som kan kombineras på önskat
            sätt.
          </h4>
          {data.map((d, i) => {
            return <ServiceBlock data={d} index={i} />;
          })}
        </div>
      </div>
    </>
  );
}

export default Services;
