import React from "react";
import logo from "../images/safedate-logga2-small-no-slogan.png";
import ln from "../images/LinkedIn_logo_initials.png.webp";
import smartdna from "../images/smartdna-logo.jpeg";
import "./footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { useTranslation, Trans } from "react-i18next";

function Footer(props) {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <div className="footer section">
      <div className="cols content-padding" style={{ alignItems: "center" }}>
        <div className="three-col">
          <a href="/" className="logo-link">
            <img alt="SafePal logo" src={logo} className="logo" />
          </a>
        </div>
        <div
          className="three-col"
          style={{ flexDirection: "row", alignItems: "center" }}
        >
          <a
            href="https://www.linkedin.com/company/safedate-ab/"
            className="social"
          >
            <FontAwesomeIcon icon={faLinkedin} />
          </a>
          <a href="mailto:info@yoursafepal.com" className="mailto social">
            <FontAwesomeIcon icon={faEnvelope} color={"#fff"} />
          </a>
        </div>
        <div className="three-col">
          <button
            className="download-cta"
            onClick={() => props.setShowModal(!props.showModal)}
          >
            {t("miscDownload")}
          </button>
        </div>
      </div>
      <div className="cols section content-padding">
        <div className="three-col">
          <h4>{t("footerTitleFirst")}</h4>
          <p className="bold no-margin">SafePal Company AB</p>
          <p className="no-margin">Strandvägen 47</p>
          <p className="no-margin">114 56, Stockholm</p>
          <p className="">Org-nr: 559361-6146</p>
          <p className="no-margin bold">Copyright © 2023 SafePal Company AB</p>
        </div>
        <div className="three-col">
          <h4>{t("footerTitleSecond")}</h4>

          <a
            href={"mailto:info@yoursafepal.com"}
            className="bold no-margin no-decor"
          >
            info@yoursafepal.com
          </a>
        </div>
        <div className="three-col">
          <h4>{t("footerTitleThird")}</h4>
          <p>
            <a href={"/terms-buy"} className="bold no-margin">
              {t("footerLinksFirst")}
            </a>
          </p>
          <p>
            <a href={"/terms-privacy"} className="bold no-margin">
              {t("footerLinksSecond")}
            </a>
          </p>
          {/* <p>
            <a className="bold no-margin">Användarvillkor</a>
          </p> */}
        </div>
      </div>
    </div>
  );
}

export default Footer;
