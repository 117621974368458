import React from "react";

import ln from "../images/LinkedIn_logo_initials.png.webp";
import "./about-us.css";

function AboutUs(props) {
  return (
    <>
      <div className="about-us">
        <div className="section">
          <div className="cols" style={{ justifyContent: "center" }}>
            <div className="one-col" style={{ maxWidth: 680 }}>
              <h1>Om SafePal</h1>
              <h4>
                Över 2.7 miljoner svenskar upplever otrygghet till den grad att
                de undviker vardagliga aktiviteter. Nu är det dags att vi skapar
                det trygga samhälle vi förtjänar!
              </h4>
              <h4>
                Bjud in dina SafePals i appen; vännen/systern/föräldern du vill
                ska kunna finnas där som stöd när du känner dig otrygg. Appen
                funkar som en trygghetsåtgärd för alla, tjejen som ska cykla hem
                från sitt sommarjobb, personen som ska på dejt med en okänd,
                kvinnan som ensam går hem efter krogen.
              </h4>
              <h4>
                Vår vision är att ALLA ska kunna känna sig trygga, oavsett
                ekonomisk situation, geografisk plats, läggning etc.
              </h4>
              <h4>
                Vi har lång och gedigen erfarenhet från polisen och militär
                underrättelsetjänst, och har därmed stor erfarenhet som
                säkerhetsexperter. Vidare har vi djup kompetens inom
                personbedömningar, utvärderingar, research, executive search och
                rekrytering.
              </h4>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
