import React from "react";
import "./contact.css";

function Contact(props) {
  return (
    <>
      <div className="contact">
        <div className="section">
          <h1>Kontakta oss</h1>
        </div>
      </div>
    </>
  );
}

export default Contact;
