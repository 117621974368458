import React from "react";

import ln from "../images/LinkedIn_logo_initials.png.webp";
import "./terms.css";
import { useTranslation, Trans } from "react-i18next";
function TermsPrivacy(props) {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <>
      <div className="terms">
        <div className="section">
          <div className="cols" style={{ justifyContent: "center" }}>
            <div className="one-col" style={{ maxWidth: 680 }}>
              <h1>{t("intPolicyTitle")}</h1>
              <p>{t("intPolicyDesc")}</p>
              <h3>{t("intPolicyInfoSubTitle")}</h3>
              <p>{t("intPolicyInfoParaFirst")}</p>
              <ul style={{ paddingLeft: 30, marginBottom: 20 }}>
                <li>{t("intPolicyInfoBulletName")}</li>
                <li>{t("intPolicyInfoBulletEmail")}</li>
                <li>{t("intPolicyInfoBulletPhone")}</li>
                <li>{t("intPolicyInfoBulletSsn")}</li>
                <li>{t("intPolicyInfoBulletUser")}</li>
                <li>{t("intPolicyInfoBulletDevice")}</li>
              </ul>
              <p>{t("intPolicyInfoParaSecond")}</p>
              <ul style={{ paddingLeft: 30, marginBottom: 20 }}>
                <li>{t("intPolicyInfoBulletPayment")}</li>
                <li>{t("intPolicyInfoBulletLocation")}</li>
                <li>{t("intPolicyInfoBulletContacts")}</li>
                <li>{t("intPolicyInfoBulletPictures")}</li>
                <li>{t("intPolicyInfoBulletSound")}</li>
              </ul>
              <p>{t("intPolicyInfoParaThird")}</p>
              <h3>{t("intPolicyUsageTitle")}</h3>
              <p>{t("intPolicyUsageParaFirst")}</p>
              <h3>{t("intPolicyShareTitle")}</h3>
              <p>{t("intPolicyShareParaFirst")}</p>
              <h3>{t("intPolicySafetyTitle")}</h3>
              <p>{t("intPolicySafetyParaFirst")}</p>
              <h3>{t("intPolicyRightsTitle")}</h3>
              <p>{t("intPolicyRightsParaFirst")}</p>
              <h3>{t("intPolicyContactTitle")}</h3>
              <p>
                {t("intPolicyContactParaFirst")}{" "}
                <a href="mailto: info@yoursafepal.com">info@yoursafepal.com</a>
              </p>
              <button
                style={{ alignSelf: "flex-start" }}
                onClick={() => {
                  window.Intercom("show");
                }}
                className="cta"
              >
                {t("miscChat")}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsPrivacy;
