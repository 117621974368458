import React from "react";
import "./faq.css";

function Faq(props) {
  return (
    <>
      <div className="faq">
        <div className="section">
          <h1>FAQ</h1>
        </div>
      </div>
    </>
  );
}

export default Faq;
