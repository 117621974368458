import React from "react";

import ln from "../images/LinkedIn_logo_initials.png.webp";
import "./terms.css";
import { useTranslation, Trans } from "react-i18next";
function RemoveAccount(props) {
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <>
            <div className="terms">
                <div className="section">
                    <div className="cols" style={{ justifyContent: "center" }}>
                        <div className="one-col" style={{ display: 'flex', flex: 1, maxWidth: 800, flexDirection: 'column' }}>
                            <h1>Ta bort ditt konto</h1>
                            <p>För att radera ditt konto eller din data, skriv ett meddelande nedan och ange den e-post som du registrerade ditt konto med.</p>
                            <p>Vi kommer att ta bort och svara svara inom 30 dagar.</p>
                            <p>Gäller appen SafePal: Stay Safe på AppStore och Google Play</p>
                            <p>Du kan också maila oss på info@yoursafepal.com</p>
                            <p>Den data som kommer raderas är:</p>
                            <p>- Personnummer</p>
                            <p>- Email</p>
                            <p>- Telefonnummer</p>
                            <p>- För- och efternamn</p>
                            <p>- Platsdata</p>
                            <p>- Ljudinspelningar vid alarm</p>
                            <p>Ditt konto kommer att anonymiseras, men finnas kvar, utan dina personliga uppgifter.</p>
                            <form>
                                <label style={{ width: '100%' }}>Meddelande:</label>
                                <textarea style={{ width: '100%' }}>

                                </textarea>
                                <label style={{ width: '100%', display: 'flex' }}>Email: </label>
                                <input type="email" /><br />
                                <button
                                    style={{}}
                                    onClick={() => {

                                    }}
                                    className="cta"
                                >
                                    Skicka meddelande
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RemoveAccount;
