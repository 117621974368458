import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import UILayout from "./ui/ui-layout";
import Home from "./views/home";
import AboutUs from "./views/about-us";
import Faq from "./views/faq";
import Contact from "./views/contact";
import Order from "./views/order";
import Services from "./views/services";
import HowItWorks from "./views/how-it-works";
import "./App.css";
import "./ui/variables.css";
import { AppContext, AppContextProvider } from "./appContext";
import TermsBuy from "./views/terms_buy";
import TermsPrivacy from "./views/terms_privacy";
import RemoveAccount from "./views/remove_account";

function App() {
  const [currentRoute, setCurrentRoute] = useState("/");
  const context = useContext(AppContext);

  useEffect(() => {
    console.log("CURRENT ROUTE", currentRoute);
  }, [currentRoute]);

  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  };
  useEffect(() => {
    context.setWindowDimensions(getWindowDimensions());
    context.setLoadedWindowDimensions(getWindowDimensions());
  }, []);
  // useEffect(() => {
  //   const handleResize = () => {
  //     context.setWindowDimensions(getWindowDimensions());
  //   };
  //   window.addEventListener("resize", handleResize);
  //   return () => window.removeEventListener("resize", handleResize);
  // }, []);

  return (
    <div className="App">
      <Router>
        <UILayout currentRoute={currentRoute} setCurrentRoute={setCurrentRoute}>
          <Routes>
            <Route
              path="/"
              element={
                <Home
                  currentRoute={currentRoute}
                  setCurrentRoute={setCurrentRoute}
                />
              }
            />
            <Route
              path="/about-us"
              element={
                <AboutUs
                  currentRoute={currentRoute}
                  setCurrentRoute={setCurrentRoute}
                />
              }
            />
            <Route
              path="/faq"
              element={
                <Faq
                  currentRoute={currentRoute}
                  setCurrentRoute={setCurrentRoute}
                />
              }
            />
            <Route
              path="/order"
              element={
                <Order
                  currentRoute={currentRoute}
                  setCurrentRoute={setCurrentRoute}
                />
              }
            />
            <Route
              path="/how-it-works"
              element={
                <HowItWorks
                  currentRoute={currentRoute}
                  setCurrentRoute={setCurrentRoute}
                />
              }
            />
            <Route
              path="/services"
              element={
                <Services
                  currentRoute={currentRoute}
                  setCurrentRoute={setCurrentRoute}
                />
              }
            />
            <Route
              path="/contact"
              element={
                <Contact
                  currentRoute={currentRoute}
                  setCurrentRoute={setCurrentRoute}
                />
              }
            />
            <Route
              path="/terms-buy"
              element={
                <TermsBuy
                  currentRoute={currentRoute}
                  setCurrentRoute={setCurrentRoute}
                />
              }
            />
            <Route
              path="/terms-privacy"
              element={
                <TermsPrivacy
                  currentRoute={currentRoute}
                  setCurrentRoute={setCurrentRoute}
                />
              }
            />
            <Route
              path="/remove-account"
              element={
                <RemoveAccount
                  currentRoute={currentRoute}
                  setCurrentRoute={setCurrentRoute}
                />
              }
            />
          </Routes>
        </UILayout>
      </Router>
    </div>
  );
}

export default App;
