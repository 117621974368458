import React from "react";
import "./order.css";

function Order(props) {
  return (
    <>
      <div className="order">
        <div className="section">
          <h1>Beställ</h1>
        </div>
      </div>
    </>
  );
}

export default Order;
