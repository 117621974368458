import React, { useState } from "react";
import { Fade, Slide, Zoom } from "react-awesome-reveal";
import "./home.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./how-it-works.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle, faTimes } from "@fortawesome/pro-regular-svg-icons";
import hero1 from "../images/hero_1.png";
import hero2 from "../images/hero_2.png";
import hero3 from "../images/hero_3.png";
import hero4 from "../images/hero_4.png";
import hero5 from "../images/hero_5.png";
import apple from "../images/apple.png";
import google from "../images/google.png";
import handInHand from "../images/hand-i-hand.png";
import { useTranslation, Trans } from "react-i18next";
import { faHeart, faHeartbeat } from "@fortawesome/pro-solid-svg-icons";
function Home(props) {
  const [showHero, setShowHero] = useState(false);
  const [showTextBlock, setShowTextBlock] = useState(false);
  const [showPartners, setShowPartners] = useState(false);
  const [showGreen, setShowGreen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const data = [
    {
      title: t("sliderFirstTitle"),
      desc: [`${t("sliderFirstDesc")}`],
      bullets: [],
      imageUrl: require("../images/hero_1.png"),
    },
    {
      title: t("sliderSecondTitle"),
      desc: [[`${t("sliderSecondDesc")}`]],
      bullets: [],
      imageUrl: require("../images/hero_2.png"),
    },
    {
      title: t("sliderThirdTitle"),
      desc: [[`${t("sliderThirdDesc")}`]],
      bullets: [],
      imageUrl: require("../images/hero_3.png"),
    },
    {
      title: t("sliderForthTitle"),
      desc: [[`${t("sliderForthDesc")}`]],
      bullets: [],
      imageUrl: require("../images/hero_4.png"),
    },
    {
      title: t("sliderFifthTitle"),
      desc: [[`${t("sliderFifthDesc")}`]],
      bullets: [],
      imageUrl: require("../images/hero_5.png"),
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0%",
    beforeChange: (current, next) => setCurrentSlide(next),
    adaptiveHeight: true,
  };

  return (
    <>
      <div className="home">
        <div className="hero section content-padding">
          <h1 style={{ alignSelf: "center" }}>
            <h2>{t("mainTitle")}</h2>
          </h1>
          <div className="images">
            <img
              alt="Descritive images from the app"
              src={hero5}
              className="left outer"
            />
            <img
              alt="Descritive images from the app"
              src={hero2}
              className="left"
            />
            <img
              alt="Descritive images from the app"
              src={hero1}
              className="middle"
            />
            <img
              alt="Descritive images from the app"
              src={hero3}
              className="right"
            />
            <img
              alt="Descritive images from the app"
              src={hero4}
              className="right outer"
            />
          </div>
          <div className="app-btn-wrp">
            <p>👇 {t("download")} 👇</p>
            <div className="inner">
              <a
                href="https://apps.apple.com/se/app/safepal-stay-safe/id6446502380"
                target="_blank"
              >
                <img
                  alt="appstore_download"
                  src={apple}
                  className="app-btn apple"
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=com.sweden.safepal"
                target="_blank"
              >
                <img
                  alt="google_download"
                  src={google}
                  className="app-btn google"
                />
              </a>
            </div>
          </div>
        </div>
        {/* <div className="section lexbase info-puff content-padding">
          <div className="cols">
            <div className="two-col">
              <img src={require("../images/lexbase_logo.png")} />
              <FontAwesomeIcon
                icon={faTimes}
                style={{
                  position: "relative",
                  top: 5,
                  fontSize: 30,
                  marginRight: 5,
                }}
              />
              <img
                src={require("../images/safedate-logga2-small-no-slogan.png")}
              />
            </div>
            <div className="two-col">
              <p>{t("lexbase")}</p>
            </div>
          </div>
        </div> */}
        <div className="section info-puff content-padding">
          <div className="cols">
            <div className="two-col">
              <img src={handInHand} className="content-image" />
            </div>
            <div className="two-col">
              <h2>{t("puffTitle")}</h2>
              <p>{t("puffDescFirst")}</p>
              <p>{t("puffDescSecond")}</p>
              <p>{t("puffDescThird")}</p>
            </div>
          </div>
        </div>
        <div className="how-it-works">
          <div className="section purple">
            <h1>{t("miniPuffFirstTitle")}</h1>
            <div className={`slider slide_${currentSlide}`}>
              <Slider {...settings}>
                {data.map((item, i) => {
                  return (
                    <div className="slide">
                      <img src={item.imageUrl} />
                      <h2>{item.title}</h2>
                      {item.desc.map((text, j) => {
                        return <p>{text}</p>;
                      })}
                      {item.bullets.map((bull, j) => {
                        return (
                          <div className="bullets">
                            <FontAwesomeIcon
                              icon={faCircle}
                              style={{
                                position: "relative",
                                top: 5,
                                fontSize: 15,
                                marginRight: 5,
                              }}
                            />
                            <p>{bull}</p>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </Slider>
            </div>
          </div>
        </div>

        {/* <button
                onClick={() => {
                  window.Intercom("show");
                }}
                className="cta simple"
              >
                Chatta med oss
              </button> */}
      </div>
    </>
  );
}

export default Home;
