import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./how-it-works.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-regular-svg-icons";
import { useTranslation, Trans } from "react-i18next";

function HowItWorks(props) {
  const [currentSlide, setCurrentSlide] = useState(0);

  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const data = [
    {
      title: t("sliderFirstTitle"),
      desc: [`${t("sliderFirstDesc")}`],
      bullets: [],
      imageUrl: require("../images/hero_1.png"),
    },
    {
      title: t("sliderSecondTitle"),
      desc: [[`${t("sliderSecondDesc")}`]],
      bullets: [],
      imageUrl: require("../images/hero_2.png"),
    },
    {
      title: t("sliderThirdTitle"),
      desc: [[`${t("sliderThirdDesc")}`]],
      bullets: [],
      imageUrl: require("../images/hero_3.png"),
    },
    {
      title: t("sliderForthTitle"),
      desc: [[`${t("sliderForthDesc")}`]],
      bullets: [],
      imageUrl: require("../images/hero_4.png"),
    },
    {
      title: t("sliderFifthTitle"),
      desc: [[`${t("sliderFifthDesc")}`]],
      bullets: [],
      imageUrl: require("../images/hero_5.png"),
    },
  ];
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "0%",
    beforeChange: (current, next) => setCurrentSlide(next),
    adaptiveHeight: true,
  };
  return (
    <>
      <div className="how-it-works">
        <div className="section">
          <h1>{t("miniPuffFirstTitle")}</h1>
          <div className={`slider slide_${currentSlide}`}>
            <Slider {...settings}>
              {data.map((item, i) => {
                return (
                  <div className="slide">
                    <img src={item.imageUrl} />
                    <h2>{item.title}</h2>
                    {item.desc.map((text, j) => {
                      return <p>{text}</p>;
                    })}
                    {item.bullets.map((bull, j) => {
                      return (
                        <div className="bullets">
                          <FontAwesomeIcon
                            icon={faCircle}
                            style={{
                              position: "relative",
                              top: 5,
                              fontSize: 15,
                              marginRight: 5,
                            }}
                          />
                          <p>{bull}</p>
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </Slider>
          </div>
          <div className="cols">
            <div className="two-col free">
              <h3>{t("howPuffFirstTitle")}</h3>
              <p>{t("howPuffFirstDesc")}</p>
            </div>
            <div className="two-col payed">
              <div className="cols">
                <div className="one-col">
                  <h3>{t("howPuffSecondTitle")}</h3>
                  <p>{t("howPuffSecondDesc")}</p>
                  <p className="price">
                    <span>49</span> {t("howPuffSecondPrice")}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HowItWorks;
