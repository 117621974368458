import React from "react";
import { useTranslation, Trans } from "react-i18next";
import ln from "../images/LinkedIn_logo_initials.png.webp";
import "./terms.css";

function TermsBuy(props) {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <>
      <div className="terms">
        <div className="section">
          <div className="cols" style={{ justifyContent: "center" }}>
            <div className="one-col" style={{ maxWidth: 680 }}>
              <h1>{t("paymentTermsTitle")}</h1>
              <p>{t("paymentTermsDesc")}</p>

              <h3>{t("paymentTermsServiceSubTitle")}</h3>

              <p>{t("paymentTermsServiceParaFirst")}</p>

              <p>{t("paymentTermsServiceParaSecond")}</p>
              {/* 
              <h3>Tilläggstjänster</h3>

              <p>
                Tjänsten innehåller ett antal tilläggstjänster som delvis kan
                användas kostnadsfritt. För att nyttja tilläggstjänsterna
                obegränsat behöver användaren antingen göra ett engångsköp eller
                starta en prenumeration.
              </p> */}

              <h3>{t("paymentTermsPriceSubTitle")}</h3>

              <p>{t("paymentTermsPriceParaFirst")}</p>

              <p>{t("paymentTermsPriceParaSecond")}</p>

              <p>{t("paymentTermsPriceParaThird")}</p>

              <h3>{t("paymentTermsDeliverySubTitle")}</h3>

              <p>{t("paymentTermsDeliveryParaFirst")}</p>

              <h3>{t("paymentTermsRegretSubTitle")}</h3>

              <p>{t("paymentTermsRegretParaFirst")}</p>

              <p>{t("paymentTermsRegretParaSecond")}</p>

              <p>{t("paymentTermsRegretParaThird")}</p>

              <p>{t("paymentTermsRegretParaForth")}</p>

              <h3>{t("paymentTermsSendBackSubTitle")}</h3>

              <p>{t("paymentTermsSendBackParaFirst")}</p>

              <h3>{t("paymentTermsInfoSubTitle")}</h3>

              <p>{t("paymentTermsInfoParaFirst")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default TermsBuy;
