import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faGlobe } from "@fortawesome/pro-light-svg-icons";
import logo from "../images/safedate-logga2-small-no-slogan.png";
import "./main-menu.css";
import { AppContext } from "../appContext";
import { useTranslation, Trans } from "react-i18next";

function MainMenu(props) {
  const context = useContext(AppContext);
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <div className={`main-menu ${context.stickyMenu}`}>
      <div className="inner">
        <div>
          <a href="/" className="logo-link">
            <img alt="SafePal logo" src={logo} className="logo" />
          </a>
        </div>
        <div className="lang-btns">
          <FontAwesomeIcon
            icon={faGlobe}
            color={"#fff"}
            style={{ marginRight: 5 }}
          />
          <button
            className={`${i18n.language === "se" ? "active" : "not-active"}`}
            onClick={() => {
              changeLanguage("se");
            }}
          >
            SE
          </button>
          <button
            className={`${i18n.language === "en" ? "active" : "not-active"}`}
            onClick={() => {
              changeLanguage("en");
            }}
          >
            EN
          </button>
        </div>
        <div className="menu">
          <div className="links">
            {/* <a
              className={props.currentRoute === "how-it-works" ? "active" : ""}
              href="/how-it-works"
            >
              {t("miniPuffFirstTitle")}
            </a> */}

            {/* <a
              className={props.currentRoute === "about-us" ? "active" : ""}
              href="/about-us"
            >
              Om SafePal
            </a> */}
            {/* <a
              className={props.currentRoute === "contact" ? "active" : ""}
              href="/contact"
            >
              Kontakt
            </a> */}
          </div>
          <button
            className="download-cta"
            href="#"
            onClick={() => props.setShowModal(!props.showModal)}
          >
            {t("miscDownload")}
          </button>
          {/* <button
            className={`mobile-menu-btn ${
              props.showMobileMenu ? "active" : ""
            }`}
            href="#"
            onClick={() => props.setShowMobileMenu(!props.showMobileMenu)}
          >
            <FontAwesomeIcon icon={faBars} style={{ fontSize: 24 }} />
          </button> */}
        </div>
      </div>
    </div>
  );
}

export default MainMenu;
